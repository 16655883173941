<template>
  <div id="cont" class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-page-header @back="$router.go(-1)" content="新增商品"></el-page-header>
    <el-form class="goods-form" ref="goodsForm" :model="goodsForm" :rules="goodsRules">
      <div class="basic-info">
        <h6>基本信息</h6>
        <el-form-item label="商品名称" prop="name">
          <el-input class="width-370" v-model="goodsForm.name" placeholder="商品名称" :disabled="action === 'look'" maxLength="30"></el-input>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-radio :disabled="action === 'look'" v-model="goodsForm.type" label="VIRTUAL">虚拟商品</el-radio>
          <el-radio :disabled="action === 'look'" v-model="goodsForm.type" label="IN_KIND">实物商品</el-radio>
        </el-form-item>
        <el-form-item label="商品分类">
          <el-button :disabled="action === 'look'" @click="selectedCategory" class="m-right-5" type="primary">选择分类</el-button>
          <span>{{ goodsForm.categoryId ? '已选分类' : '未选择' }}</span>
        </el-form-item>
      </div>
      <div class="attribute-info">
        <h6>属性信息</h6>
        <div>
          <el-form-item label="套餐内容">
            <el-button :disabled="action === 'look'" @click="setMealVisible = true" type="primary">添加套餐</el-button>
          </el-form-item>
          <el-form-item label="已设套餐" prop="productSkus">
            <el-table :data="goodsForm.productSkus" border size="mini" style="width: 700px">
              <el-table-column label="序号">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="套餐名称"></el-table-column>
              <el-table-column prop="price" label="售价"></el-table-column>
              <el-table-column prop="quantity" label="库存"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button @click="editSetMeal(scope.$index, scope.row, 'look')" type="text">查看</el-button>
                  <el-button @click="editSetMeal(scope.$index, scope.row)" type="text">编辑</el-button>
                  <el-button @click="delSetMeal(scope.$index, scope.row)" type="text">
                    <el-link type="danger">删除</el-link>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="供应酒店" prop="hotelId">
            <el-select :disabled="action === 'look'" v-model="goodsForm.hotelId" class="width-370">
              <el-option v-for="item in hotelOpt" :key="item.id" :label="item.hotelName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="goods-image">
        <h6>商品图片</h6>
        <el-form-item label="封面图" prop="image">
          <div @click="visibleImage = true; imageType = 'master'" class="add-image" style="margin-left: 66px">
            <el-image v-if="goodsForm.image" :src="goodsForm.image"></el-image>
            <div v-else><i class="el-icon-plus"></i></div>
          </div>
        </el-form-item>
        <el-form-item label="详情图" prop="productImages">
          <el-row class="detail-image-box" type="flex">
            <div class="image-item" v-for="(item, index) in goodsForm.productImages" :key="index" @mouseenter="mask = 'block'" @mouseleave="mask = 'none'">
              <el-image :src="item.url"></el-image>
              <div class="mask" :style="{'display': mask}"><i @click="handleRemoveImage(item)" class="el-icon-delete"></i></div>
            </div>
            <div class="add-image" @click="visibleImage = true; imageType = 'detail'"><i class="el-icon-plus"></i></div>
          </el-row>
        </el-form-item>
      </div>
      <div class="goods-detail">
        <h6>商品详情</h6>
        <el-form-item label="商品详情">
          <vue-tinymce v-model="goodsForm.productDetail" :setting="setting"></vue-tinymce>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button @click="$router.go(-1)">取消</el-button>
        <el-button @click="handleSave" type="primary">保存</el-button>
      </el-form-item>
    </el-form>
    <Cropper :visible="visibleImage" :imageType="imageType" :setOpt="setOption"
             @setImageInfo="getImageInfo" @closeUploadImg="closeUploadImg"></Cropper>
    <el-dialog title="选择分类" :visible.sync="categoryVisible" width="550px">
      <el-tree ref="tree" :data="categoryTree" show-checkbox default-expand-all node-key="id"
               :props="{ children: 'children', label: 'name' }" @check="handleCheck"></el-tree>
      <span slot="footer">
         <el-button @click="categoryVisible = false">取消</el-button>
         <el-button type="primary" @click="saveCheckCategory">确定</el-button>
       </span>
    </el-dialog>
    <el-dialog title="添加套餐" :visible.sync="setMealVisible" width="650px" @before-close="closeSetMeal"
               :close-on-click-modal="false">
      <el-form class="set-meal-form" ref="setMealForm" :model="setMealForm" :rules="setMealRules">
        <el-form-item label="套餐名称" prop="name">
          <el-input class="width-280" v-model="setMealForm.name" clearable maxLength="30"></el-input>
        </el-form-item>
        <el-form-item label="套餐售价" prop="price">
          <el-input-number class="width-280" v-model="setMealForm.price" :step="1" :min="0.01" :precision="2" clearable></el-input-number>
        </el-form-item>
        <el-form-item label="套餐原价" prop="originalPrice">
          <el-input-number class="width-280" v-model="setMealForm.originalPrice" :step="1" :min="0.01" :precision="2" clearable></el-input-number>
        </el-form-item>
        <el-form-item label="套餐库存" prop="quantity">
          <el-input class="width-280" v-model.number="setMealForm.quantity" clearable></el-input>
        </el-form-item>
        <el-form-item class="set-meal-explain" label="套餐说明">
          <div class="m-bottom-20" v-for="(item, index) in productSkuDirectionList"
              :key="index">
            <el-row type="flex" justify="space-between">
              <h5>{{ item.mainTitle }}</h5>
              <el-link @click="addDirections(item)" type="primary">添加</el-link>
            </el-row>
            <el-table :data="item.data" border size="mini">
              <el-table-column label="标题" width="140">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.title" size="mini"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="内容">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.content" size="mini" type="textarea"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="60">
                <template slot-scope="scope">
                  <el-button @click="delDirections(item.data, scope.$index)" type="text" size="mini">
                    <el-link type="danger">删除</el-link>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeSetMeal">取消</el-button>
        <el-button @click="addPackage" type="primary" :disabled="setMealType === 'look'">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Cropper from '@/components/local/Cropper'
import { createGoods, selectHotel, uploadThumbImage, getDetail } from '@/api/shop/goods/goods';
import { getCategoryList } from '@/api/shop/goods/category';
import { mapState } from 'vuex';
export default {
  name: 'goodsCreate',
  data() {
    const validImage = (rule, value, callback) => {
      if (this.action === 'create' && value.length === 0) callback(new Error('详情图不能为空！'))
      else callback()
    }
    return {
      crumbs: new Map([
        ['特惠商城'], ['商品中心'], ['商品列表', '/preferential_mall/goods/list'], ['创建商品']
      ]),
      goodsForm: {
        name: '',
        type: 'IN_KIND',
        categoryId: '',
        categoryLevel1: '',
        categoryLevel2: '',
        categoryLevel3: '',
        image: '',
        thumbImage: '',
        productSkus: [],
        hotelId: '',
        productImages: [],
        productDetail: ''
      },
      goodsRules: {
        name: [{ required: true, message: '商品名称不能为空！' }],
        hotelId: [{ required: true, message: '供应酒店不能为空！' }],
        productSkus: [{ required: true, message: '商品套餐不能为空！' }],
        image: [{ required: true, message: '封面图不能为空！' }],
        productImages: [{ required: true, validator: validImage, message: '详情图不能为空！' }]
      },
      setMealForm: {
        name: '',
        price: 0,
        originalPrice: 0,
        quantity: 0,
        productSkuDirections: []
      },
      initSetMealForm: {},
      setMealRules: {
        name: [{ required: true, message: '套餐名称不能为空！' }],
        price: [
          { required: true, message: '套餐售价不能为空！' },
        ],
        originalPrice: [
          { required: true, message: '套餐原价不能为空！' },
        ],
        quantity: [
          { required: true, message: '库存不能为空！' },
          { type: 'number', message: '库存数量必须为数字！' }
        ]
      },
      productSkuDirectionList: [
        { mainTitle: '购买须知', type: 'PURCHASE_NOTES', data: [] },
        { mainTitle: '费用说明', type: 'DESCRIPTION_OF_FEES', data: [] },
        { mainTitle: '使用说明', type: 'INSTRUCTIONS', data: [] }
      ],
      setMealIndex: null,
      content: '',
      setting:{
        menubar: false,
        toolbar: "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
        toolbar_drawer: "sliding",
        quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        plugins: "link image media table lists fullscreen quickbars",
        language_url:"/tinymce/langs/zh_CN.js",
        language: 'zh_CN', //本地化设置
        height: 350,
        images_upload_handler:(blobInfo, uploadsSuccess, uploadErr) => {
          // 上传图片base64转换成自定义上传
          this.tinymceUploadImage(blobInfo,uploadsSuccess,uploadErr)
        }
      },
      categoryVisible: false,
      categoryTree: [],
      categoryCheckId: {},
      setMealVisible: false,
      hotelOpt: [],
      action: 'create',
      fileList: [],
      modifyForm: {
        id: '',
        removeSkuIds: [],
        removeImgIds: [],
        removeSkuDirectionIds: []
      },
      removeSkuDirectionIds: [],
      visibleImage: false,
      imageType: 'master',
      mask: 'none',
      setMealType: ''
    }
  },
  computed: {
    ...mapState(['hotelInfo']),
    setOption() {
      if (this.imageType === 'detail') return { autoCropWidth: '500', fixedNumber: [1.6, 1], mode: '500px 300px' }
      else return { autoCropWidth: '300', fixedNumber: [1, 1], mode: '300px 300px' }
    }
  },
  components: {
    Cropper
  },
  mounted() {
    this.getCategorise()
    this.getHotelOpt()
    this.initSetMealForm = { ...this.setMealForm }
    this.action = this.$route.query.action
    this.modifyForm.id = this.$route.query.id
    if (this.action === 'modify' || this.action === 'look') this.getGoodsInfo()
  },
  methods: {
    // 获取当前商品信息
    getGoodsInfo() {
      const query = `/${ this.modifyForm.id }`;
      getDetail(query).then(({ success, records }) => {
        if (success) for (const k in this.goodsForm) k in records && (this.goodsForm[k] = records[k])
      })
    },
    // 打开商品分类会话框
    selectedCategory() {
      this.categoryVisible = true
      if (this.action === 'modify') {
        this.$nextTick(() => this.$refs.tree.setCheckedKeys([ this.goodsForm.categoryId ]))
      }
    },
    // 获取商品分类
    getCategorise() {
      let data= {
        hotelId: this.hotelInfo.id
      }
      getCategoryList(data).then(({ success, records }) => {
        if (success) this.categoryTree = this.insertAttribute(records)
      })
    },
    // 插入禁选属性
    insertAttribute(categoryTree) {
      categoryTree.forEach(item => {
        if (item.level !== 3) item.disabled = true;
        if (item.hasOwnProperty('children')) this.insertAttribute(item.children)
      })
      return categoryTree
    },
    // 处理分类选中
    handleCheck(data, node) {
      if (node.checkedKeys.length > 0) {
        this.categoryCheckId.categoryId = data.id
        this.categoryCheckId.categoryLevel1 = node.checkedNodes.concat(node.halfCheckedNodes).find(i => {
          return data.parentId === i.id
        }).parentId
        this.categoryCheckId.categoryLevel2 = data.parentId
        this.categoryCheckId.categoryLevel3 = data.id
        this.$refs.tree.setCheckedKeys([ data.id ])
      }else {
        this.categoryCheckId.categoryId = ''
        this.categoryCheckId.categoryLevel1 = ''
        this.categoryCheckId.categoryLevel2 = ''
        this.categoryCheckId.categoryLevel3 = ''
      }
    },
    // 确定保存分类
    saveCheckCategory() {
      this.categoryVisible = false
      Object.assign(this.goodsForm, this.categoryCheckId)
    },
    // 添加套餐说明
    addDirections(item) {
      item.data.push({ title: '', content: '', type: item.type })
    },
    // 删除套餐说明
    delDirections(data, index) {
      if (data[index].id) this.removeSkuDirectionIds.push(data[index].id);
      data.splice(index, 1);
    },
    // 编辑套餐
    editSetMeal(index, row, type) {
      this.setMealType = type
      this.setMealIndex = index
      this.setMealVisible = true
      for (const k in this.setMealForm) k in row && (this.setMealForm[k] = row[k])
      row.id && (this.setMealForm.id = row.id)
      this.productSkuDirectionList.forEach((i1) => {
        this.setMealForm.productSkuDirections.forEach((i2) => {
          if (i1.type === i2.type) i1.data.push(i2)
        })
      })
    },
    // 删除套餐
    delSetMeal(index, { id }) {
      id && this.modifyForm.removeSkuIds.push(id)
      this.goodsForm.productSkus.splice(index, 1)
    },
    // 保存套餐
    addPackage() {
      this.$refs.setMealForm.validate(valid => {
        if (!valid) return;
        this.setMealForm.productSkuDirections.length = 0
        this.productSkuDirectionList.forEach(i => {
          this.setMealForm.productSkuDirections.push(...i.data)
        })
        if (this.setMealIndex || this.setMealIndex === 0) {
          this.goodsForm.productSkus.splice(this.setMealIndex, 1)
        }
        this.goodsForm.productSkus.push(this.setMealForm)
        this.modifyForm.removeSkuDirectionIds = [ ...this.removeSkuDirectionIds ];
        this.closeSetMeal()
      })
    },
    // 关闭套餐会话框
    closeSetMeal() {
      this.removeSkuDirectionIds.length = 0;
      this.setMealVisible = false
      this.setMealIndex = null
      this.setMealForm = JSON.parse(JSON.stringify(this.initSetMealForm))
      for (const v of this.productSkuDirectionList) v.data.length = 0
    },
    // 获取酒店下拉
    getHotelOpt() {
      selectHotel({}).then(({ success, records }) => {
        if (success) this.hotelOpt = records.map(item => ({ id: item.id, hotelName: item.hotelName }))
      })
    },
    // 获取上传图片信息
    getImageInfo({ url }, { uid }, type) {
      this.visibleImage = false
      if (type === 'master') this.goodsForm.thumbImage = this.goodsForm.image = url
      else this.goodsForm.productImages.push({ uid, master: 'NO', url, thumbUrl: url })
    },
    // 关闭上传图片会话框
    closeUploadImg() {
      this.visibleImage = false
    },
    // 移除详情图
    handleRemoveImage({ id, uid }) {
      this.goodsForm.productImages.forEach((item, index) => {
        if (item.id === id && item.uid === uid) this.goodsForm.productImages.splice(index, 1)
      })
      if (id) this.modifyForm.removeImgIds.push(id)
    },
    // 富文本上传图片后替换base64为网络路径
    tinymceUploadImage(blobInfo, success, failure) {
      const params = new FormData()
      params.append('hotelId', this.hotelInfo.id)
      params.append('file', blobInfo.blob())
      params.append('keyPrefix', 'mall/detail_images')
      params.append('height', '5000')
      params.append('width', '1000')
      uploadThumbImage(params).then(({ records }) => {
        success(records.url)
      }).catch(err => failure(err))
    },
    // 保存商品
    handleSave() {
      this.$refs.goodsForm.validate(valid => {
        if (!valid) return
        if (this.goodsForm.productDetail.indexOf('base64') !== -1) {
          return this.$message({ message: '富文本框的图片不支持剪切上传！', type: 'error' })
        }
        let params = { ...this.goodsForm, companyId: this.hotelInfo.storeId }
        if (this.action === 'modify') {
          params.productImages = params.productImages.filter(i => i.id === undefined)
          params = Object.assign({}, params, this.modifyForm)
        }
        createGoods(params, this.action).then(({ success }) => {
          if (!success) return;
          this.$router.go(-1)
          this.$message({ message: '保存商品成功！', type: 'success' })
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cont {
  .goods-form{
    background: #FFFFFF; padding: 30px;
    >div {
      margin-bottom: 30px;
      h6{ font-size: 18px; margin-bottom: 20px }
    }
    .goods-image {
      .add-image {
        width: 120px; height: 120px; border: dashed 1px #c0ccda;
        border-radius: 6px; text-align: center; cursor: pointer;
        .el-icon-plus{ font-size: 22px; color: #8c939d; line-height: 120px }
      }
      .detail-image-box{
        .image-item {
          position: relative;
          .el-image { width: 192px; }
          .mask {
            position: absolute; width: 192px; height: 120px; left: 0; top: 0;
            text-align: center; font-size: 22px; background: rgba(0, 0, 0, 0.4);
            .el-icon-delete { color: #FFFFFF; line-height: 120px }
          }
        }
        .el-image{ width: 120px; height: 120px; margin: 0 10px 0 0; }
      }
    }
  }
  .set-meal-form{
    .set-meal-explain{
      ::v-deep .el-form-item__label{ float: none }
    }
    ::v-deep .el-form-item__label{ width: 80px }
    ::v-deep .el-form-item__error{ margin-left: 78px }
  }
  ::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content{
    background-color: #ecf5ff !important; color: #409eff;
  }
}
</style>
